import { createSlice } from '@reduxjs/toolkit';
import { initialAcquisitiontMapping } from 'redux/reduxInitialStates';

const initialState = initialAcquisitiontMapping;

export const acquisitionMappingSlice = createSlice({
  name: 'acquisitionMapping',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateAcquisitionMapping: (state, action) => {
      state.mainState = action.payload;
    },
  },
});

export const { revertToInitialState, updatePlacementMapping } =
  acquisitionMappingSlice.actions;

export const selectPlacementMapping = (state) => {
  return state.acquisitionMapping;
};

export default acquisitionMappingSlice.reducer;
