import { InputGroup, Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

const IntuitaInputBar = ({ ...props }) => {
  return (
    <div className={props.className} style={props.style}>
      <InputGroup className='input-group-merge input-group-reverse'>
        <Form.Control
          type={props.type || 'search'}
          placeholder={props.placeholder}
          value={props.value}
          id={props.id}
          ref={props.ref}
          onChange={props.onChange}
          format={props.format}
          mask={props.mask}
          as={props.as}
          min={props.min}
          onKeyPress={props.onKeyPress}
          readOnly={props.readOnly}
        />
        <InputGroup.Text>
          {props.icon ? (
            <FeatherIcon
              icon={props.icon}
              color={props.color || 'var(--intuita-main)'}
              size='1.25em'
            />
          ) : (
            ''
          )}
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
};

export default IntuitaInputBar;
