import { createSlice } from '@reduxjs/toolkit';
import { initialBonusCodesMapping } from 'redux/reduxInitialStates';

const initialState = initialBonusCodesMapping;

export const bonusCodesMappingSlice = createSlice({
  name: 'bonusCodesMapping',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updatePlacementMapping: (state, action) => {
      state.mainState = action.payload;
    },
  },
});

export const { revertToInitialState, updatePlacementMapping } =
  bonusCodesMappingSlice.actions;

export const selectPlacementMapping = (state) => {
  return state.bonusCodesMapping;
};

export default bonusCodesMappingSlice.reducer;
