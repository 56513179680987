import { createSlice } from '@reduxjs/toolkit';
import { initialMappingQuerySetupVal } from 'redux/reduxInitialStates';

const initialState = initialMappingQuerySetupVal;

export const mappingQuerySetupSlice = createSlice({
  name: 'mappingQuerySetup',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateMappingQueryAcquisitionSetup: (state, action) => {
      state.acquisition = action.payload;
    },
    updateMappingQueryCrmSetup: (state, action) => {
      state.crm_setup = action.payload;
    },
    updateMappingQueryBonusCodesSetup: (state, action) => {
      state.bonusCodes_setup = action.payload;
    },
  },
});

export const {
  revertToInitialState,
  updateMappingQueryAcquisitionSetup,
  updateMappingQueryCrmSetup,
  updateMappingQueryBonusCodesSetup,
} = mappingQuerySetupSlice.actions;

export const selectMappingQuerySetup = (state) => {
  return state.mappingQuerySetup;
};

export default mappingQuerySetupSlice.reducer;
