import { useState, useRef, useEffect } from 'react';
import betmgmLogo from '../../assets/img/logos/betmgmlogoonly.png';
import useAuth from '../../hooks/useAuth.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useOutsideClick from '../../utils/useOutsideClick';
import UserProfileDropdown from '../components/UserProfileDropdown';
import { listUserGroups } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import NavigationItems from '../components/NavigationItems';
import SettingsDropdown from '../components/SettingsDropdown';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import NotificationsSidebarCont from '../components/NotificationsSidebarCont';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat,
  split,
} from '@apollo/client';
import '../styles/topnav.css';

const httpLink2 = new HttpLink({
  uri: 'https://graphql2.datadq.com/v1/graphql',
});

const wsLink2 = new GraphQLWsLink(
  createClient({
    url: 'wss://graphql2.datadq.com/v1/graphql',
    connectionParams: () => {
      return fetchAuthSession({
        forceRefresh: true,

        bypassCache: true,
      }).then((data) => {
        return {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.tokens?.idToken?.toString(),
            'Sec-WebSocket-Protocol': 'graphql-ws',
          },
        };
      });
    },
  })
);

const splitLink2 = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink2,
  httpLink2
);

const authMiddleware = setContext((operation, { headers }) =>
  fetchAuthSession({
    forceRefresh: true,

    bypassCache: true,
  }).then((data) => ({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + data.tokens?.idToken?.toString(),
    },
  }))
);

const client2 = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, splitLink2),
});

const Topnav = ({ ...props }) => {
  const {
    navData,
    addNewCampaign,
    removedClients,
    setSelectedRows,
    setAddNewCampaign,
    showImportCsvModal,
    selectedClientQuery,
    setShowImportCsvModal,
    setSelectedClientQuery,
    showImportConfirmationModal,
    setShowImportConfirmationModal,
  } = props;

  const { login, setLogin } = useAuth();

  const client = generateClient();

  const location = useLocation();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState([]);
  const [clientsQueries, setClientsQueries] = useState([]);
  const viewAsDropdownRef = useRef(null);
  const setupDropdownRef = useRef(null);
  const settingsDropdownRef = useRef(null);
  const qualityDropdownRef = useRef(null);
  const reportingDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);

  function handleQueryChange(e) {
    let clientQuery = e;
    setSelectedClientQuery(clientQuery);
    sessionStorage.setItem('ClientQuery', clientQuery);
  }

  const [isViewAsOpen, setIsViewAsOpen] = useState(false);
  const [isSetupDropdownOpen, setIsSetupDropdownOpen] = useState(false);
  const [isReportingDropdownOpen, setIsReportingDropdownOpen] = useState(false);
  const [isQualityDropdownOpen, setIsQualityDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedClientQuery);
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    fetchRegions();
    // eslint-disable-next-line
  }, [login.permissions, login.regionRefresh]);

  useOutsideClick(setupDropdownRef, () => setIsSetupDropdownOpen(false));
  useOutsideClick(qualityDropdownRef, () => setIsQualityDropdownOpen(false));
  useOutsideClick(viewAsDropdownRef, () => setIsViewAsOpen(false));
  useOutsideClick(settingsDropdownRef, () => setIsSettingsOpen(false));
  useOutsideClick(reportingDropdownRef, () =>
    setIsReportingDropdownOpen(false)
  );
  useOutsideClick(profileDropdownRef, () => setIsProfileOpen(false));

  const fetchRegions = async () => {
    try {
      let curUserGroups = login.permissions.filter((item) =>
        item.startsWith('Account.')
      );
      let nextTokenApi;
      const results = [];

      do {
        let response = await client.graphql({
          query: listUserGroups,
          variables: { input: { nextToken: nextTokenApi } },
        });
        const json = response.data.listUserGroups;
        const { nextToken, items } = json;
        nextTokenApi = nextToken;
        results.push(...items);
      } while (nextTokenApi);
      let uniqueRegions = [...new Set(results.map((item) => item['region']))];

      let filteredResults = results.filter((result) =>
        curUserGroups.includes(result.name)
      );
      setClientsQueries(filteredResults);
      setRegionList(uniqueRegions);
      setUserRole(login.permissions.filter((item) => item.startsWith('Role.')));
    } catch (error) {
      console.error(error);
      setRegionList([]);
      setClientsQueries([]);
      setUserRole([]);
    }
  };

  return (
    <div className='topnav-wrapper'>
      <div className='topnav-left'>
        {/* LOGO */}
        <div>
          <img
            src={betmgmLogo}
            style={{ height: '32px', width: '32px' }}
            alt='data dq logo'
          />
        </div>
        {location.pathname !== '/mappings-extension' && (
          <>
            {/* NAV LINKS */}
            <NavigationItems
              navData={navData}
              location={location}
              addNewCampaign={addNewCampaign}
              showImportCsvModal={showImportCsvModal}
              setShowImportCsvModal={setShowImportCsvModal}
              isSetupDropdownOpen={isSetupDropdownOpen}
              setIsSetupDropdownOpen={setIsSetupDropdownOpen}
              login={login}
              setupDropdownRef={setupDropdownRef}
              setAddNewCampaign={setAddNewCampaign}
              showImportConfirmationModal={showImportConfirmationModal}
              setShowImportConfirmationModal={setShowImportConfirmationModal}
              qualityDropdownRef={qualityDropdownRef}
              isReportingDropdownOpen={isReportingDropdownOpen}
              setIsReportingDropdownOpen={setIsReportingDropdownOpen}
              isQualityDropdownOpen={isQualityDropdownOpen}
              setIsQualityDropdownOpen={setIsQualityDropdownOpen}
              reportingDropdownRef={reportingDropdownRef}
              isProfileOpen={isProfileOpen}
              setIsProfileOpen={setIsProfileOpen}
              isViewAsOpen={isViewAsOpen}
              setIsViewAsOpen={setIsViewAsOpen}
            />
          </>
        )}
      </div>

      <div className='topnav-right'>
        {location.pathname !== '/mappings-extension' && (
          <>
            {/* ACTION ICONS */}
            <NotificationsSidebarCont
              login={login}
              client={client2}
              selectedClientQuery={selectedClientQuery}
            />

            <SettingsDropdown
              login={login}
              navData={navData}
              isSettingsOpen={isSettingsOpen}
              setIsSettingsOpen={setIsSettingsOpen}
              showImportCsvModal={showImportCsvModal}
              settingsDropdownRef={settingsDropdownRef}
              setShowImportCsvModal={setShowImportCsvModal}
            />

            <div className='topnav-vertical_divider' />
          </>
        )}

        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <UserProfileDropdown
            profileDropdownRef={profileDropdownRef}
            setIsProfileOpen={setIsProfileOpen}
            isProfileOpen={isProfileOpen}
            login={login}
            setLogin={setLogin}
            userRole={userRole}
            viewAsDropdownRef={viewAsDropdownRef}
            isViewAsOpen={isViewAsOpen}
            clientsQueries={clientsQueries}
            setSelectedOption={setSelectedOption}
            handleQueryChange={handleQueryChange}
            setIsViewAsOpen={setIsViewAsOpen}
            selectedOption={selectedOption}
            selectedClientQuery={selectedClientQuery}
            setSelectedRows={setSelectedRows}
            regionList={regionList}
            removedClients={removedClients}
          />
          <div>
            <h4
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '500',
                margin: '0',
                color: 'white',
              }}
            >
              <div
                style={{
                  fontSize: '1rem',
                }}
              >
                {selectedClientQuery.replaceAll('_', ' ')}
              </div>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
