import React, { useEffect, Suspense, useState } from 'react';
import useAuth from './hooks/useAuth.js';
import { Link, useNavigate } from 'react-router-dom';
import { authCurrentUser, getUserData, storeActivity } from 'utils/dataFetch';
import { generateClient } from 'aws-amplify/api';
import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
} from 'aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import LoadingSpin from 'react-loading-spin';
import { getCompany } from './graphql/queries.js';
import Topnav from './NAVIGATION/layouts/Topnav.jsx';
import { useLazyQuery } from '@apollo/client';
import {
  GET_COLUMN_WIDTH,
  GET_RELATIONSHIPS,
  GET_S2_DATADQ_SEQUENCES,
  GET_S2_DATADQ_SEQUENCES_STRING,
  GET_S2_LIST_NAMES_STRING,
  GET_S2_LIST_VALUES_STRING,
  GET_S2_LIST_GLOBAL_VALUES_STRING,
  GET_S2_TAXONOMIES,
  GET_S2_LIST_GLOBAL_NAMES_STRING,
  GET_S2_TAXONOMIES_STRING,
  GET_S2_TAXONOMY_INDEX_HIGHEST,
} from './graphql/hasura/s2queries.js';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectMappingType,
  updateMappingType,
} from './redux/slices/mappingType/mappingType.slice.js';
import { useDispatch } from 'react-redux';
import { selectVerified } from './redux/slices/verified/verified.slice.js';
import { listQueryBuilder } from './utils/graphQlQueryBuilders/listQueryBuilder.js';
import { taxonomyQueryBuilder } from './utils/graphQlQueryBuilders/taxonomyQueryBuilder.js';
import { taxonomySequenceQueryBuilder } from './utils/graphQlQueryBuilders/taxonomySequenceQueryBuilder.js';
import { lazyRetry } from './utils/lazyWithRetry.js';
import toast, { Toaster } from 'react-hot-toast';
import XIcon from './components/shared/icons/XIcon.jsx';
import './styles/theme.scss';

const Pages = React.lazy(() => lazyRetry(() => import('./pages')));

function App() {
  const client = generateClient();
  const { login, setLogin } = useAuth();
  const location = useLocation();

  const [mappingMode, setMappingMode] = useState('simple');
  const [selectedClientQuery, setSelectedClientQuery] = useState('');
  const [jsonNavData, setJsonNavData] = useState({});
  const [refreshSeed, setRefreshSeed] = useState(1);
  const [removedClients, setRemovedClients] = useState([]);

  const [isEmailVerified, setIsEmailVerified] = useState('');

  const navigate = useNavigate();

  function getUserAttributes() {
    if (login.formType === 'signedIn') {
      fetchUserAttributes().then((data) => {
        setIsEmailVerified(data.email_verified ? 'verified' : 'unverified');
      });
    }
  }

  useEffect(() => {
    getUserAttributes();

    if (
      login.formType === 'signedIn' &&
      isEmailVerified === 'unverified' &&
      login.firstName &&
      login.lastName
    ) {
      setTimeout(() => {
        const customToad = toast.custom(
          <div
            style={{
              width: '480px',
            }}
          >
            <div
              className='bg-white p-3 d-flex'
              style={{ border: '3px solid var(--intuita-red)' }}
            >
              <div className='flex-column'>
                <div className='d-flex justify-content-between'>
                  <h2 style={{ marginBottom: 0 }}>Confirm Email</h2>
                  <XIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => toast.dismiss(customToad)}
                  />
                </div>
                <hr />
                <p
                  className='m-0 w-100'
                  style={{
                    textAlign: 'justify',
                  }}
                >
                  Dear{' '}
                  {login.firstName && login.lastName
                    ? login.firstName + ' ' + login.lastName
                    : 'user'}
                  , please confirm your email by following the link{' '}
                  <Link
                    style={{ color: 'var(--intuita-main)' }}
                    to={`/profile/${login.firstName + login.lastName}`}
                  >
                    HERE
                  </Link>
                  , or you could do it in profile settings later. Thank you for
                  your cooperation.
                </p>
              </div>
            </div>
          </div>,
          {
            duration: 2500,
          }
        );
      }, 5000);
    }
  }, [login.formType, isEmailVerified, login.firstName, login.lastName]);

  useEffect(() => {
    getUserAttributes();

    if (isEmailVerified === 'unverified' && login.firstName && login.lastName) {
      const timeoutHandler = () => {
        const customToad = toast.custom(
          <div
            style={{
              width: '480px',
            }}
          >
            <div
              className='bg-white p-3 d-flex'
              style={{ border: '3px solid var(--intuita-red)' }}
            >
              <div className='flex-column'>
                <div className='d-flex justify-content-between'>
                  <h2 style={{ marginBottom: 0 }}>Confirm Email</h2>
                  <XIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => toast.dismiss(customToad)}
                  />
                </div>
                <hr />
                <p
                  className='m-0 w-100'
                  style={{
                    textAlign: 'justify',
                  }}
                >
                  Dear{' '}
                  {login.firstName && login.lastName
                    ? login.firstName + ' ' + login.lastName
                    : 'user'}
                  , please confirm your email by following the link{' '}
                  <Link
                    style={{ color: 'var(--intuita-main)' }}
                    to={`/profile/${login.firstName + login.lastName}`}
                  >
                    HERE
                  </Link>
                  , or you could do it in profile settings later. Thank you for
                  your cooperation.
                </p>
              </div>
            </div>
          </div>,
          {
            duration: 5000,
          }
        );
        setTimeout(timeoutHandler, 1800000);
      };

      const timeoutId = setTimeout(timeoutHandler, 1800000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [login.formType, isEmailVerified, login.firstName, login.lastName]);

  /******************************************/
  // Mapping Data Loading
  /******************************************/
  const [relationshipsData, setRelationshipsData] = useState([]);

  // main table definitions state variable, used throughout the app
  const [activeRelationshipsData, setActiveRelationshipsData] = useState([]);

  // main mappings data state variable
  const [mappingData, setMappingData] = useState(null);
  const [mappingLoad, setMappingLoad] = useState(true);

  // state variable for storing mapping data which value have changed in some way
  const [mutatedData, setMutatedData] = useState([]);

  // state variable for storing the mapping structure (Placement, Creative etc)
  const [mappingTypeValue, setMappingTypeValue] = useState(
    useSelector(selectMappingType)
  );

  // state variable for storing taxonomy prefix objects
  const [taxDataSequences, setTaxDataSequences] = useState(null);

  // state variable for storing
  const [taxonomyData, setTaxonomyData] = useState(null);

  // state variable for the currently highest tax index (possibly obsolete)
  const [taxonomyIndexData, setTaxonomyIndexData] = useState(null);

  //
  const [listNameData, setListNameData] = useState(null);

  //
  const [listValuesData, setListValuesData] = useState(null);

  const [showImportCsvModal, setShowImportCsvModal] = useState(false);
  const [showImportConfirmationModal, setShowImportConfirmationModal] =
    useState(false);

  // change to dynamic
  const mappingTypes = ['Acquisition', 'CRM', 'Bonus Codes', 'Token Codes'];

  // state variable for holding an array of selected rows in the mappings table
  const [selectedRows, setSelectedRows] = useState([]);

  const [
    relationshipQuery,
    { loading: relationshipLoading, error: relationshipError },
  ] = useLazyQuery(GET_RELATIONSHIPS);

  // an sync function for getting the table definitions
  // called at the start of the session and anytime the table definitions change
  // whether by adding columns or changing a particular defs props
  const queryRelationshipData = async () => {
    let relationshipData = [];
    let activeTableDefs = [];

    let queriedData = [];
    try {
      queriedData = await relationshipQuery({
        variables: {
          tableType: 'Relationships',
          where: {
            _eq: {
              client_name: selectedClientQuery,
              mapping_type: mappingTypeValue.type,
            },
          },
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      console.error(error);
    }

    relationshipData = [
      ...relationshipData,
      {
        mapping_type: mappingTypeValue.type,
        table_defs: queriedData?.data?.get_relationships,
      },
    ];
    for (let i = 0; i < relationshipData.length; i++) {
      if (relationshipData[i].mapping_type === mappingTypeValue.type) {
        activeTableDefs = cloneDeep(relationshipData[i].table_defs);
      }
    }
    setRelationshipsData(relationshipData);
    setActiveRelationshipsData(activeTableDefs);
  };

  const mappingTypeDispatch = useDispatch();

  // function for switching between mapping structures (not used)
  const changeActiveTableDefs = (mappingType, activeObject) => {
    let clonedRelationshipData = cloneDeep(relationshipsData);
    let clonedActiveRelationshipsData = cloneDeep(activeRelationshipsData);
    let clonedMappingType = cloneDeep(mappingTypeValue);
    if (clonedMappingType.type !== mappingType) {
      for (let i = 0; i < clonedRelationshipData.length; i++) {
        if (clonedRelationshipData[i].mapping_type === mappingType) {
          clonedActiveRelationshipsData = clonedRelationshipData[i].table_defs;
        }
      }
      clonedMappingType.type = mappingType;
      setActiveRelationshipsData(clonedActiveRelationshipsData);
      mappingTypeDispatch(updateMappingType(mappingType));
      setMappingTypeValue(clonedMappingType);
      if (activeObject !== undefined) {
        if (
          activeObject.sidebar_comp_string === 'cross_join' ||
          activeObject.sidebar_comp_string === 'url_setup'
        ) {
          let clonedCrossJoinRows = [];
          for (let i = 0; i < activeObject.comb_row_data.length; i++) {
            if (mappingType === activeObject.comb_row_data[i].mapping_type) {
              clonedCrossJoinRows = cloneDeep(
                activeObject.comb_row_data[i].rows
              );
              break;
            }
          }
          setSelectedRows(clonedCrossJoinRows);
        }
      } else {
        setSelectedRows([]);
        setMutatedData([]);
      }
      resetComponent();
    }
  };
  const verifiedMode = useSelector(selectVerified);

  const [earlyLoad, setEarlyLoad] = useState(false);

  // async function for querying mapping data
  // queryies the mapping data in chunks for faster loading times
  // once all the chunks have been received the data is pooled together
  // and updates the state variable
  const queryMappingData = async () => {
    try {
      setMappingData([]);
    } catch (error) {
      setMappingData(null);
      console.error(error);
    } finally {
      setMappingLoad(false);
    }
  };

  /******************************************/

  // graphql function for fetching taxonomy prefix data
  const taxSeqDataQuery = taxonomySequenceQueryBuilder(
    GET_S2_DATADQ_SEQUENCES_STRING,
    mappingTypeValue.type,
    selectedClientQuery
  );

  // graphql function for conditionally fetching taxonomy prefix data
  const [startSequenceData, { data: seqData, loading: seqLoad }] = useLazyQuery(
    selectedClientQuery === 'Global'
      ? GET_S2_DATADQ_SEQUENCES
      : taxSeqDataQuery,
    selectedClientQuery === 'Global'
      ? {
          variables: {
            mapping_type: mappingTypeValue.type,
            client_name: selectedClientQuery,
          },
          fetchPolicy: 'no-cache',
        }
      : {
          fetchPolicy: 'no-cache',
        }
  );

  // graphql function for fetching column widths for the mapping table
  const [
    startColumnQuery,
    { data: columnWidthData, loading: columnWidthLoading },
  ] = useLazyQuery(GET_COLUMN_WIDTH, {
    variables: {
      mappingType: mappingTypeValue.type,
      tableType: 'Relationships',
      clientName: selectedClientQuery,
    },
    fetchPolicy: 'no-cache',
  });

  // async function for starting up the process of fetching taxonomy prefixes
  const querySequnceData = async () => {
    try {
      // let queriedData = await startSequenceData();
      //setListClientData(queriedData?.data?.s2_lists);
      await startSequenceData();
    } catch (error) {
      //setListClientData(null);
      console.error(error);
    }
  };

  /******************************************/
  //Taxonomy Data Loading
  /******************************************/
  const [
    taxSequencesQuery,
    { loading: loadingTaxDataSequence, error: taxSequencesError },
  ] = useLazyQuery(
    taxonomySequenceQueryBuilder(
      GET_S2_DATADQ_SEQUENCES_STRING,
      mappingTypeValue.type,
      selectedClientQuery
    ),
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [taxQuery, { loading: loadingTaxonomies, error: taxonomyError }] =
    useLazyQuery(
      taxonomyQueryBuilder(
        GET_S2_TAXONOMIES_STRING,
        mappingTypeValue.type,
        selectedClientQuery
      ),
      {
        fetchPolicy: 'no-cache',
      }
    );

  const [taxIndexQuery, { loading: loadingTaxonomyIndex }] = useLazyQuery(
    GET_S2_TAXONOMY_INDEX_HIGHEST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const queryTaxSequences = async () => {
    try {
      let queriedData = await taxSequencesQuery();
      setTaxDataSequences(queriedData?.data?.s2_datadq_sequence);
    } catch (error) {
      setTaxDataSequences(null);
      console.error(error);
    }
  };

  const querytaxonomies = async () => {
    try {
      let queriedData = await taxQuery();
      setTaxonomyData(queriedData?.data?.s2_taxonomies);
    } catch (error) {
      setTaxonomyData(null);
      console.error(error);
    }
  };

  const queryTaxIndex = async () => {
    try {
      let queriedData = await taxIndexQuery();
      setTaxonomyIndexData(queriedData?.data?.s2_taxonomies);
    } catch (error) {
      setTaxonomyIndexData(null);
      console.error(error);
    }
  };

  /******************************************/
  //Table Defs Loading
  /******************************************/
  // TO BE MODIFIED AFTER DARREN SETS UP TABLE DEFS PROPERLY
  // (add client name to the existing table definition objects)
  // SAME FOR DQ DEFINITIONS AND ANYWHERE ELSE WHERE THE QUERY IS USED
  //

  const [listNamesQuery, { loading: listNameLoading }] = useLazyQuery(
    listQueryBuilder(
      GET_S2_LIST_NAMES_STRING,
      mappingTypeValue.type,
      selectedClientQuery
    ),
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [
    listValuesQuery,
    { loading: listValuesLoading, error: listValuesError },
  ] = useLazyQuery(
    listQueryBuilder(
      GET_S2_LIST_VALUES_STRING,
      mappingTypeValue.type,
      selectedClientQuery
    ),
    { fetchPolicy: 'no-cache' }
  );

  const querylistNames = async () => {
    try {
      let queriedData = await listNamesQuery();
      setListNameData(queriedData?.data?.s2_lists);
    } catch (error) {
      setListNameData(null);
      console.error(error);
    }
  };

  const querylistValues = async () => {
    try {
      let queriedData = await listValuesQuery();
      setListValuesData(queriedData?.data?.s2_lists);
    } catch (error) {
      setListValuesData(null);
      console.error(error);
    }
  };

  const [addNewCampaign, setAddNewCampaign] = useState(false);
  const cleanupDataOnSwitch = () => {
    setMappingData(null);
    setMappingLoad(true);
    setRelationshipsData([]);
    setActiveRelationshipsData([]);
    setListValuesData(null);
    setListNameData(null);
    setTaxDataSequences(null);
    setTaxonomyData(null);
    setTaxonomyIndexData(null);
  };
  /******************************************/
  useEffect(() => {
    cleanupDataOnSwitch();
    if (selectedClientQuery !== '' && selectedClientQuery !== 'No Client') {
      //Relatioships Data

      queryRelationshipData();
      querySequnceData();
      querylistNames();
      querylistValues();
      queryTaxSequences();
      querytaxonomies();
      queryTaxIndex();
    }
    return () => {
      cleanupDataOnSwitch();
    };
  }, [mappingTypeValue.type, selectedClientQuery, verifiedMode.verified]);

  // to run when we go from one page to another (column width should adjust again then)
  useEffect(() => {
    if (selectedClientQuery !== '') {
      startColumnQuery();
    }
  }, [
    mappingTypeValue.type,
    selectedClientQuery,
    addNewCampaign,
    showImportCsvModal,
  ]);

  useEffect(() => {
    if (
      activeRelationshipsData !== undefined &&
      activeRelationshipsData !== null
    ) {
      if (activeRelationshipsData?.length !== 0) {
        if (mappingTypeValue.type === activeRelationshipsData[0].mapping_type) {
          setMappingData(null);
          queryMappingData();
        }
      }
    }
  }, [activeRelationshipsData, mappingMode]);

  //Function to prevent users from leaving the site if there is unsaved data
  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (mutatedData?.length !== 0) {
      window.addEventListener('beforeunload', handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
  }, [mutatedData]);

  useEffect(() => {
    checkUser();
  }, []);

  //Set up loged user
  async function checkUser() {
    try {
      let curUser = await authCurrentUser();
      setLogin({ ...login, formType: 'signedIn', username: curUser.username });
    } catch (err) {
      setLogin({ ...login, formType: 'signUp' });
      console.error('Error at [App.js][checkUser]: ', err);
    }
  }

  async function getNavData() {
    const person = await client.graphql({
      query: getCompany,
      variables: { name: 'IntuitaDevs' },
    });
    let parsedData = JSON.parse(person.data.getCompany.clientQueryFilters[0]);
    setJsonNavData(parsedData);
  }

  useEffect(() => {
    if (login.username !== '') {
      fetchUser();
      getNavData();
      storeActivity(login);
    }
  }, [login.formType === 'signedIn']);

  useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    //tracker methods like "setCustomDimension" should be called before "trackPageView"
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//matomo.datadq.com/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '8']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
  }, []);

  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = 'https://matomo.datadq.com/js/container_lOYEatA2.js';
    s.parentNode.insertBefore(g, s);
  }, []);

  const fetchUser = async () => {
    try {
      let user = await fetchAuthSession({
        forceRefresh: true,
      });
      const { username } = await getCurrentUser({
        forceRefresh: true,
      });
      let userData = await getUserData(username);
      let adminPermissions = user.tokens.idToken.payload['cognito:groups'] || [
        'Role.Missing_Permissions',
      ];

      const parsedTaxonomies = JSON.parse(userData.taxonomies) || [];
      setLogin(() => {
        return {
          ...login,
          displayUsername: userData.displayUsername,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          avatar: null,
          company: userData.company,
          role: userData.role,
          permissions: adminPermissions,
          lastUsed: userData.lastUsed,
          layout: userData.layout,
          phone: userData.phone,
          birthday: userData.birthday,
          taxonomies: parsedTaxonomies,
        };
      });
      let selectedClientFromSessionsStorage =
        sessionStorage.getItem('ClientQuery');
      if (!selectedClientFromSessionsStorage) {
        if (selectedClientQuery === '') {
          let accountFilter = adminPermissions.filter((item) =>
            item.startsWith('Account.')
          );
          if (accountFilter.length !== 0) {
            sessionStorage.setItem(
              'ClientQuery',
              accountFilter.includes('Account.BetMGM')
                ? 'BetMGM'
                : accountFilter[0].slice(accountFilter[0].indexOf('.') + 1)
            );
            setSelectedClientQuery(
              accountFilter.includes('Account.BetMGM')
                ? 'BetMGM'
                : accountFilter[0].slice(accountFilter[0].indexOf('.') + 1)
            );
          } else {
            setSelectedClientQuery('No Client');
          }
        }
      } else {
        setSelectedClientQuery(selectedClientFromSessionsStorage);
      }
      if (adminPermissions[0] === 'Role.Missing_Permissions') {
        navigate('/unauthorized');
      }
    } catch (error) {
      setSelectedClientQuery('');
      console.error('[Error while fetching user]:', error);
    }
  };

  const resetComponent = () => {
    setRefreshSeed(Math.random());
  };

  const locationPath = [
    '/signin',
    '/forgot-password',
    '/reset-password',
    '/change-password',
  ];

  return (
    <div
      style={{
        background: locationPath.some((path) =>
          location.pathname.includes(path)
        )
          ? '#111'
          : 'white',
        height: '100vh',
      }}
    >
      <Toaster />
      <div style={{ height: '100%' }}>
        {(Object.keys(jsonNavData).length !== 0 &&
          selectedClientQuery.length !== 0) ||
        login.formType === 'signUp' ? (
          <div style={{ height: '100%' }}>
            {login.formType === 'signedIn' && (
              <Topnav
                setSelectedClientQuery={setSelectedClientQuery}
                selectedClientQuery={selectedClientQuery}
                navData={jsonNavData}
                resetComponent={resetComponent}
                addNewCampaign={addNewCampaign}
                setAddNewCampaign={setAddNewCampaign}
                setSelectedRows={setSelectedRows}
                showImportCsvModal={showImportCsvModal}
                setShowImportCsvModal={setShowImportCsvModal}
                showImportConfirmationModal={showImportConfirmationModal}
                setShowImportConfirmationModal={setShowImportConfirmationModal}
                removedClients={removedClients}
              />
            )}

            <Suspense
              fallback={
                <div className='vh-100 vw-100 d-flex flex-column justify-content-center align-items-center'>
                  <LoadingSpin size={50} primaryColor='var(--intuita-main)' />
                  <h1>DataDQ page is loading...please wait...</h1>
                </div>
              }
            >
              <Pages
                // key={refreshSeed}
                setMappingMode={setMappingMode}
                selectedClientQuery={selectedClientQuery}
                navData={jsonNavData}
                setNavData={setJsonNavData}
                resetComponent={resetComponent}
                //Relationship Data
                relationshipsData={relationshipsData}
                activeRelationshipsData={activeRelationshipsData}
                //Mapping Data
                mappingTypeValue={mappingTypeValue}
                setActiveRelationshipsData={setActiveRelationshipsData}
                setMappingTypeValue={setMappingTypeValue}
                relationshipLoading={relationshipLoading}
                mappingTypes={mappingTypes}
                changeActiveTableDefs={changeActiveTableDefs}
                verifiedMode={verifiedMode}
                mappingLoad={mappingLoad}
                mappingError={/* mappingError */ undefined}
                mappingData={mappingData}
                seqData={seqData}
                seqLoad={seqLoad}
                columnWidthData={columnWidthData}
                columnWidthLoading={columnWidthLoading}
                setMappingData={setMappingData}
                earlyLoad={earlyLoad}
                setEarlyLoad={setEarlyLoad}
                //Taxonomy Data
                loadingTaxDataSequence={loadingTaxDataSequence}
                taxDataSequences={taxDataSequences}
                taxSequencesError={taxSequencesError}
                loadingTaxonomies={loadingTaxonomies}
                taxonomyData={taxonomyData}
                taxonomyError={taxonomyError}
                loadingTaxonomyIndex={loadingTaxonomyIndex}
                taxonomyIndexData={taxonomyIndexData}
                queryTaxSequences={queryTaxSequences}
                //Table Defs
                relationshipError={relationshipError}
                listNameLoading={listNameLoading}
                listNameData={listNameData}
                listValuesLoading={listValuesLoading}
                listValuesData={listValuesData}
                listValuesError={listValuesError}
                //Mutated Mapping Data
                mutatedData={mutatedData}
                setMutatedData={setMutatedData}
                //For Luka
                setTaxDataSequences={setTaxDataSequences}
                setTaxonomyData={setTaxonomyData}
                setTaxonomyIndexData={setTaxonomyIndexData}
                setListNameData={setListNameData}
                setListValuesData={setListValuesData}
                //Test
                queryListData={querylistValues}
                queryRelationshipData={queryRelationshipData}
                addNewCampaign={addNewCampaign}
                setAddNewCampaign={setAddNewCampaign}
                showImportCsvModal={showImportCsvModal}
                setShowImportCsvModal={setShowImportCsvModal}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                showImportConfirmationModal={showImportConfirmationModal}
                setShowImportConfirmationModal={setShowImportConfirmationModal}
                removedClients={removedClients}
                setRemovedClients={setRemovedClients}
              />
            </Suspense>
          </div>
        ) : (
          <div className='vh-100 vw-100 d-flex flex-column justify-content-center align-items-center'>
            <LoadingSpin size={50} primaryColor='var(--intuita-main)' />
            <h1>DataDQ page is loading...please wait...</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
